<template>
    <b-navbar sticky class="m-1 p-1" id="#navbar">
        <b-navbar-nav>
            <b-nav-item :active='$route.name === "Home"' :to="'/'">Sofia Kalantaridou</b-nav-item>
            <b-nav-item v-if="$route.name !== 'Home'" @click="scrollToTop">{{ $route.name }}</b-nav-item>
        </b-navbar-nav>
    </b-navbar>
</template>

<script>
export default {
    name: 'NavigationComponent',
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    }
}
</script>

<style scoped>

</style>