<template>
    <b-col xs="12" sm="12" md="6" lg="4" xl="4" class="portfolio-item" v-bind:key="link">
        <router-link :to="{path: link}">
            <div class="portfolio-image-wrapper h-100 w-100">
                <b-img-lazy 
                    blank-color="#777" 
                    fluid 
                    class="portfolio-image h-100 w-100" 
                    :srcset="'/assets/' + link + '/preview.min.webp'" 
                    :alt="link + ' preview'">
                </b-img-lazy>
                <div class="portfolio-text">
                    <h5 class="m-0 p-2 text-dark" v-text="name"></h5>
                </div>
            </div>
        </router-link>
    </b-col>
</template>

<script>
export default {
    name: "PortfolioItem",
    props: {
        name: String,
        link: String
    },
    methods: {
        
    }
}
</script>