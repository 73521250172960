<template>
    <div>
        <div class="p-5">
            <h1 v-text=name></h1>
            <p v-text="description"></p>
            <h5 class="p-0 m-0">Renders</h5>
            <b-img-lazy blank-color="#777" v-for="i in renderCount" :key="'render_' + i" offset="720"
                :src="'/assets/' + link + '/3d/' + i + '.min.webp'" fluid class="h-100 w-100 p-3"
                :alt="link + ' render ' + i">
            </b-img-lazy>
            <div v-if="planCount > 0">
                <h5 class="p-0 m-0">Σχέδια</h5>
                <b-img-lazy blank-color="#777" v-for="i in planCount" :key="'plan_' + i" offset="720"
                    :src="'/assets/' + link + '/plans/' + i + '.min.webp'" fluid class="h-100 w-100 p-3"
                    :alt="link + ' plan ' + i">
                </b-img-lazy>
            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: "PortfolioItem",
    props: {
        name: String,
        link: String,
        description: String,
        renderCount: Number,
        planCount: Number
    },
    mounted() {
        this.scrollToTop();
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    computed: {
    },
    watch: {
    }
}
</script>