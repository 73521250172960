<template>
    <PortfolioItem 
        name="Ξενοδοχείο στη Σαντορίνη" 
        link="santorini"
        :render-count="7"
        :plan-count="0"
    />
</template>
<script>
import PortfolioItem from '@/components/PortfolioItem.vue';

export default {
    name: "SantoriniVue",
    components: { PortfolioItem }
}
</script>