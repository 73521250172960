<template>
    <PortfolioItem 
        name="Σχεδιασμός Spa-Χαμάμ και ξενοδοχείου" 
        link="hotel-spa"
        description="Η ιδέα αποτελείται από δυο βασικά υψομετρικά επίπεδα το -4.00μ. και το +0.00μ. Στο επίπεδο +0.00μ. βρίσκουμε μια πισίνα, τα γραφεία διεύθυνσης και το τριώροφο ξενοδοχείο που ξεκινά απο το +0.00μ. lobby του και κατεβαίνει μέχρι και το -7.50μ. Στο επίπεδο -4.00μ. υπάρχει ένας εσωτερικός χώρος ο οποίος στεγάζει 3 πισίνες, τα αποδυτήρια, 2 χώρους μασάζ και την βάση των χαμάμ. Επίσης υπάρχει ένα γυμναστήριο και τα αποδυτήρια του που κοιτάνε προς την εξωτερική πισίνα. Στον εξωτερικό χώρο υπάρχουν 2 πισίνες και μια διαδρομή που οδηγεί στο εστιατόριο, και κατεβαίνωντας παρακατω βρίσκουμε το καφε. Στην αριστερή μερία βρίσκεται η είσοδος και το lobby στο -1.50μ. και κατεβαίνωντας στο -4.50μ. στον εξωτερικό χώρο βρίσκουμε ένα αμφιθέατρο και έναν χώρο για yoga."
        :render-count="27"
        :plan-count="1"
    />
</template>
<script>
import PortfolioItem from '@/components/PortfolioItem.vue';

export default {
    name: "HotelSpaVue",
    components: { PortfolioItem }
}
</script>