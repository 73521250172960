<template>
    <PortfolioItem 
        name="Σχεδιασμός Δημοτικού σχολείου" 
        link="school"
        description="Το σχολείο έχει νοτιοανατολικό προσανατολισμό και εκτίνεται περιμετρικά του οικοπέδου, δημιουργώντας μια μεγάλη πιο ιδιωτική αυλή στο κέντρο του. Δεξιά και αριστερά υπάρχουν δυο μικρότερες αυλές. Το κτίριο είναι διώροφο, στο ισόγειο (0.00μ) υπάρχουν 5 τάξεις σε σχήμα οκταγώνου, έτσι ώστε να υπάρχει η δυνατότητα αλλαγής της θέσης του πίνακα και των θέσεων με ποικίλους τρόπους ανάλογα με το μάθημα αλλά και για να υπάρχει καλύτερος φωτισμός. Στο ίδιο επίπεδο υπάρχει αίθουσα εστίασης μαζί με κυλικείο, τουαλέτες, αποδυτήρια, γραφεία δασκάλων και γραφείο διευθυντή. Στον πρώτο όροφο (+4.00μ) βρίσκονται οι υπόλοιπες 6 τάξεις, βιβλιοθήκη, τουαλέτες και γραφείο δασκάλων, όπου μπορούμε να μεταβούμε είτε μέσω μιας μεγάλης ράμπας που ξεκινά απο την κεντρική είσοδο είτε μέσω των δυο κλιμακοστασίων που βρίσκονται στην νότια και στην βόρεια πλευρά του κτιρίου είτε με ανελκυστήρα. Και στα δεξιά απο την κεντρική είσοδο βρίσκεται η αίθουσα πολλαπλών χρήσεων."
        :render-count="11"
        :plan-count="1"
    />
</template>
<script>
import PortfolioItem from '@/components/PortfolioItem.vue';

export default {
    name: "SchoolVue",
    components: { PortfolioItem }
}
</script>