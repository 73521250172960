<template>
    <PortfolioItem 
        name="Εξωχικό στην Αίγινα" 
        link="aegina"
        :render-count="8"
        :plan-count="0"
    />
</template>
<script>
import PortfolioItem from '@/components/PortfolioItem.vue';

export default {
    name: "AeginaVue",
    components: { PortfolioItem }
}
</script>