import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

Vue.config.productionTip = false

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './style.css';
import Home from "@/pages/Home";
import Aegina from "@/pages/Aegina";
import Santorini from "@/pages/Santorini";
import Bungalow from "@/pages/Bungalow";
import HotelSpa from "@/pages/HotelSpa";
import Lykabyttos from "@/pages/Lykabyttos";
import School from "@/pages/School";
import Spa from "@/pages/Spa";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

const routes = [
  { name: 'Home', path: '/', component: Home },
  { name: 'Aegina', path: '/aegina', component: Aegina },
  { name: 'Santorini', path: '/santorini', component: Santorini },
  { name: 'Bungalow', path: '/bungalow', component: Bungalow },
  { name: 'Σχεδιασμός Spa-Χαμάμ και ξενοδοχείου', path: '/hotel-spa', component: HotelSpa },
  { name: 'Ανάπλαση λόφου Λυκαβηττού', path: '/lykabyttos', component: Lykabyttos },
  { name: 'Σχεδιασμός Δημοτικού σχολείου', path: '/school', component: School },
  { name: 'Spa - Λουτρά', path: '/spa', component: Spa }
];

const router = new VueRouter({
  routes: routes
});

Vue.use(VueRouter);

import VueAnalytics from 'vue-analytics';
Vue.use(VueAnalytics, { 
  id: 'G-43KNZ33901',
  router
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
