<template>
    <div>
        <b-jumbotron class="home-jumbotron">
            <div class="p-5">
                <b-row>
                    <PortfolioItemPreview name="Εξωχικό στην Αίγινα" link="aegina"/>
                    <PortfolioItemPreview name="Ξενοδοχείο στη Σαντορίνη" link="santorini"/>
                    <PortfolioItemPreview name="Bungalow" link="bungalow"/>
                    <PortfolioItemPreview name="Σχεδιασμός Spa-Χαμάμ και ξενοδοχείου" link="hotel-spa"/>
                    <PortfolioItemPreview name="Ανάπλαση λόφου Λυκαβηττού" link="lykabyttos"/>
                    <PortfolioItemPreview name="Σχεδιασμός Δημοτικού σχολείου" link="school"/>
                    <PortfolioItemPreview name="Σχεδιασμός Spa-Χαμάμ και ξενοδοχείου" link="spa"/>
                </b-row>
            </div>
        </b-jumbotron>
    </div>

</template>

<script>
import PortfolioItemPreview from '@/components/PortfolioItemPreview.vue';


export default {
    name: "HomeVue",
    components: { PortfolioItemPreview }
}
</script>

<style scoped>

</style>