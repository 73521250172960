<template>
    <PortfolioItem 
        name="Bungalow" 
        link="bungalow"
        description="Bungalows με ιδιωτικής χρήσης πισίνες που έχουν σχεδιαστεί ειδικά για να προσφέρουν επιπλέον άνεση και διακριτικότητα. Το κάθε δωμάτιο είναι 40 τ.μ., στο ισόγειο βρίσκεται ένα άνετο καθιστικό και στον όροφο υπάρχει ένα κομψά σχεδιασμένο υπνοδωμάτιο και ένα πλήρως εξοπλισμένο μπάνιο. Κατά την διάρκεια της ημέρας ο φωτισμός επιτυγχάνεται με τζαμαρίες που έχουν θέα την θάλασσα και την πισίνα."
        :render-count="6"
        :plan-count="1"
    />
</template>
<script>
import PortfolioItem from '@/components/PortfolioItem.vue';

export default {
    name: "BungalowVue",
    components: { PortfolioItem }
}
</script>