<template>
    <div id="app">
        <NavigationComponent />
        <div id="content" class="d-flex justify-content-center align-items-center">
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
        <FooterComponent />
    </div>
</template>

<script>

import NavigationComponent from "@/components/Navigation";
import FooterComponent from "@/components/Footer.vue";

export default {
    name: 'App',
    components: {
        FooterComponent,
        NavigationComponent
    },
    metaInfo: {
      title: 'Sofia Kalantaridou'
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

#app {
    font-family: Manrope, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
